import * as React from "react"
import { SEO } from "../../../components/seo"
import Layout from '../../../components/vi/layout';
import { Link } from "gatsby";

const Press = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="h-96 bg-gradient-to-tl from-primary-dark to-primary-light relative">
        <div className="absolute w-full text-center mx-auto bottom-8 z-10 px-5">
          <h1 className="animate__animated animate__fadeInDown animate__slower font-heading text-center text-3xl lg:text-4xl text-white font-bold mb-4 leading-normal mx-auto max-w-[1200px]">Kim Nguyen-Penaloza Announces Campaign for California’s 45th Congressional District</h1>
        </div>
      </section>
      <section className="px-10 lg:px-36 py-10 mt-10">
        <div className="w-[800px] mx-auto max-w-full text-base leading-loose space-y-6">
          
          <h2 className="font-bold text-2xl mb-8 text-center">Lifelong resident of CA-45 brings local government experience, homegrown roots, and record of bipartisanship to battleground congressional race</h2>

          <div className="text-justify space-y-6">
            <p>GARDEN GROVE, CA Garden Grove City Councilwoman Kim Nguyen-Penaloza today announced her campaign for the U.S House of Representatives, running in her home district of CA-45, where she grew up, attended local public schools, and has served for two terms on the Garden Grove City Council. Upon announcing her campaign, Ms. Nguyen stated:</p>
  
            <p>“Washington DC is broken. Secret deals with right-wing extremists to secure leadership is no way to govern our country. While Washington plays political games, California families are struggling, dealing with the rising cost of living, education, and healthcare, as well as climate disasters like floods and wildfires. As a councilmember, I’ve worked on bipartisan efforts to deliver new economic development, infrastructure projects, and park rehabilitation in our neighborhoods while reducing crime and homelessness. In Congress, I will be a voice for all California families while working to lower cost and resolve our mounting climate crisis. It’s time to restore integrity and accountability while delivering real results.”</p>
  
            <p>The daughter of a Vietnamese refugee and a Mexican immigrant, Kim Nguyen-Penaloza was the first in her family to graduate high school and college. At just 25, she won a historic election as the youngest person and first Latina ever elected to the Garden Grove City Council. A leader on women’s issues, Ms. Nguyen has stood up to harassment and sought equality and justice throughout Southern California and has fought to ensure that immigrants, like her parents, have the dignity and opportunity to succeed in our communities. A homegrown leader, Ms. Nguyen attended Clinton-Mendenhall Elementary, Doig Intermediate, and Santiago High School. As a healthcare expert, she will bring a much-needed public health background to Congress, having formerly worked for CalOptima and now as a Program Manager for a large health plan.</p>
  
            <p>CA-45 is one of the top pickup opportunities for Democrats in 2024. It is a Biden +6 district with a Democratic registration advantage of 5.6%. Asian American Pacific Islander (AAPI) and Latino voters are a majority of the district at 51.6% – with Vietnamese voters making up the largest block of AAPI voters at 58.4%. If elected, Ms. Nguyen would be the first person of Vietnamese descent ever elected to Congress in California and only the third in US history. She would also be the first Asian-Latina in Congress and one of a growing group of millennials seeking to make change on our most crucial issues.</p>
          </div>

          <div className="text-center mt-10 underline"><Link to="/press">Back to Press Page</Link></div>

        </div>
      </section>
    </Layout>
  )
}

export default Press

export const Head = ({location}) => <SEO title="Press" pathname={location.pathname} />
